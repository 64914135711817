import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import './image.css'

class Image extends React.Component{

    constructor() {
        super();
    }

    componentDidMount(){
        
    }

    render(){
        var image = this.props.url;
        var title = this.props.title;
        var galleryLink = this.props.galleryLink;
        var description = this.props.description;
        if(image === ''){
            return(
                <CircularProgress/>
            )
        }
        return (
            <a href={galleryLink ? galleryLink : this.getFullRes(image)} title={galleryLink ? 'Click for more pictures' : 'Click for full resolution'}>
                <img className="image" src={image} title={title} alt={description}/>
            </a>
        )
    }

    getFullRes(url){
        if(url.includes("_thumb")){
            return url.replace("_thumb.", ".").replace("/thumbs", "")
        }
        return url
    }
}

export default Image