import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';


class NavBar extends React.Component{

    render(){
        return (
            <div class="Header">
                <Button component={Link} to={'/'}>Home</Button>
                <Button component={Link} to={'/robots'}>Robots</Button>
                <Button component={Link} to={'/github'}>Github</Button>
            </div>
        );
    }
}

export default NavBar