import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import './discord.css'
import './github.css'

export default class Github extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            repos: null
        }
        this.getGitHubRepos()
    }

    getGitHubRepos = async() => {
        let response = await fetch('https://api.github.com/users/SkyeOfBreeze/repos');
        let data = await response.json();
        var repos = []
        repos.push({
            name: 'controller-for-android',
            badges: {
                'https://api.codeclimate.com/v1/badges/ff37faef91984f7cf420/maintainability': 'https://codeclimate.com/github/remotv/controller-for-android/maintainability',
                'https://github.com/remotv/controller-for-android/workflows/Android%20CI/badge.svg': '',
                'https://github.com/remotv/controller-for-android/workflows/Deploy%20build/badge.svg':''
            },
            html_url: 'https://github.com/remotv/controller-for-android',
            description: 'remo.tv open source software for pairing your Android powered robot with our site. https://remo.tv (I created and maintain this repo, but hosted under Remo.TV github account. I did not create the Remo.TV site)'
        })
        repos.push({
            name: 'ControlSDK',
            badges: {
                'https://api.codeclimate.com/v1/badges/890423da31c02a714275/maintainability': 'https://codeclimate.com/github/btelman96/ControlSDK/maintainability'
            },
            html_url: 'https://github.com/ControlSDK/ControlSDK',
            description: 'Control API for Android devices to control IoT devices, electronics boards (Arduino, Serial Motor Controllers, etc), or headless Android devices connected to other devices [WIP]'
        })
        repos = repos.concat(data)
        this.setState({repos: repos})
    }

    drawBadges = (repo) => {
        if(!repo.badges) return null
        return <div className="badge-container">
            {
                Object.keys(repo.badges).map((badgeUrl)=>{
                    var badgeLink = repo.badges[badgeUrl]
                    var image = <img src={badgeUrl} className='badge'/>
                    if(badgeLink !== ''){
                        return <a href={badgeLink}>
                            {image}
                        </a>
                    }
                    return image
                })
            }
        </div>
    }

    drawRepoInfo = () => {
        return this.state.repos.map((repo)=>{
            if(repo.fork) return null
            return <div className="container">
                <div className='repo-title'>{repo.name}</div>
                <div className='repo-description'>{repo.description}</div>
                {this.drawBadges(repo)}
                <a className='repo-button-link' href={repo.html_url}>
                    <button className='repo-button-text'>View this Repo</button>
                </a>
            </div>
        })
    }

    render(){
        return (
            <>
                <div className="container">
                    <div className='description'>GitHub is where I host my open source repos. My public repos mainly involve code for robots, or Android</div>

                    <div className='title-link'>Github</div>
                    <div className="image-container">
                        <a href='https://github.com/SkyeOfBreeze'>
                            <div className='image-text'>Go to my GitHub page</div>
                        </a>
                        <img align='center' className='image-main' src='https://avatars2.githubusercontent.com/u/4467863?v=4'/>
                    </div>
                </div>
                <div className='repos-title'>Repos</div>
                {this.state.repos ? this.drawRepoInfo() : (<CircularProgress/>)}
                <div className='spacing'/>
            </>
        );
    }
}