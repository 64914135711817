import React from 'react'
import './gallery.css'
import Image from './Image'

export default class Gallery extends React.Component{
    constructor(props){
        super(props)
    }

    render(){
        return <div className="galleryContainer">
            {this.props.images.map((image)=>{
                return <div className="imageContainer">
                    <div className="imageTitle">{image.title}</div>
                    <Image url={image.url} title={image.title} galleryLink={image.galleryLink}/>
                </div>
            })}
        </div>
    }
}