import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

export default class ControllerForRVRPrivacy extends React.Component{
    constructor(){
        super()
        this.state = {
            privacy: null
        }
    }

    componentDidMount(){
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount(){
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        this.setState({state: new Date()})
    }

    render(){
        return <iframe style={{
            backgroundColor: 'white',
            marginTop: 10,
            width: '99%',
            height: window.innerHeight - 60
        }}
        src="https://cdn1.btelman.com/privacy/controller-for-rvr.html"/>
    }
}