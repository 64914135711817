import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Home from '../pages/Home'
import Robots from '../pages/Robots';
import Github from '../pages/Github';
import ControllerForRVRPrivacy from '../pages/ControllerForRVRPrivacy';

//main class
class Main extends React.Component{
  render(){
    return (
      <Switch>
        <Route exact path='/' component={Home}></Route>
        <Route exact path='/robots' component={Robots}></Route>
        <Route exact path='/github' component={Github}></Route>
        <Route excat path="/privacy/controller-for-rvr" component={ControllerForRVRPrivacy}></Route>
      </Switch>
    );
  }
}

export default Main;