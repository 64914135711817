import React from 'react';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Image from '../components/Image';
import Gallery from '../components/Gallery';

class Robots extends React.Component{

    constructor() {
        super();
        var urls = [
            {
                url: 'https://cdn1.btelman.com/images/robots/speakerbot-android.jpg',
                title: 'SpeakerBot',
                galleryLink: 'https://photos.app.goo.gl/QnSMqKH98H8As8CS8'
            },
            {
                url: 'https://cdn1.btelman.com/images/robots/thumbs/ServoModBotV2_thumb.png',
                title: 'Moto Mods Bot V2',
            },
            {
                url: 'https://cdn1.btelman.com/images/robots/thumbs/beepboop-fancy-led_thumb.jpg',
                title: 'BeepBoop V2',
            },
            {
                url: 'https://cdn1.btelman.com/images/robots/thumbs/BeepBoop_thumb.jpg',
                title: 'BeepBoop V2',
            },
            {
                url: 'https://cdn1.btelman.com/images/robots/thumbs/BeepBoop3_thumb.jpg',
                title: 'BeepBoop V3',
            },
            {
                url: 'https://cdn1.btelman.com/images/robots/thumbs/duna_thumb.jpg',
                title: 'Duna (Dune Tracker)',
            },
            {
                url: 'https://cdn1.btelman.com/images/robots/thumbs/Mebo_thumb.jpg',
                title: 'Mebo 2.0',
            },
            {
                url: 'https://cdn1.btelman.com/images/robots/thumbs/modbot2_thumb.jpg',
                title: 'Moto Mods bot',
            },
            {
                url: 'https://cdn1.btelman.com/images/robots/thumbs/OCedar_thumb.jpg',
                title: 'OCedar robotic vacuum cleaner',
            },
            {
                url: 'https://cdn1.btelman.com/images/robots/thumbs/RVR_thumb.jpg',
                title: 'Sphero RVR',
            },
            {
                url: 'https://cdn1.btelman.com/images/robots/thumbs/servo_thumb.jpg',
                title: 'Experimental servo bot',
            },
            {
                url: 'https://cdn1.btelman.com/images/robots/thumbs/Sketchy_thumb.jpg',
                title: 'Sketchy',
            },
            {
                url: 'https://cdn1.btelman.com/images/robots/thumbs/JazzyOne-2_thumb.jpg',
                title: 'Remote controlled wheelchair',
            },
        ]
        this.state = {
            loading: false,
            images: urls
        }
    }

    render(){
        if(this.state.loading) return <CircularProgress/>
        return (
            <div>
                <div class="Spacing">{this.state.header}</div>
                <div class="Spacing">Robots!</div>
                <div class="Spacing"/>
                <Gallery images={this.state.images}/>
            </div>
        );
    }
}

export default Robots;