import React from 'react';
import './home.css'

class Home extends React.Component {

    render(){
        return <div>
            <div className="container">
                <div className="mainTitle">
                    I develop for
                </div>
                <div className="subTitle">
                    Android 
                </div>
                <div className="subTitle">
                    Virtual Reality
                </div>
                <div className="subTitle">
                    Robots
                </div>
                <div className="subTitle">
                    Remo.TV
                </div>
                <div className="subTitle">
                    Moto Mods
                </div>
            </div>
            <div className="container">
                <div className="mainTitle">
                    I program with
                </div>
                <div className="subTitle">
                    Unity3D
                </div>
                <div className="subTitle">
                    Java
                </div>
                <div className="subTitle">
                    Kotlin
                </div>
                <div className="subTitle">
                    C#
                </div>
                <div className="subTitle">
                    Javascript/Typescript in mainly React and NodeJS
                </div>
                <div className="subTitle">
                    Arduino/C/C++
                </div>
                <div className="subTitle">
                    Python
                </div>
            </div>
            <div className="container">
                <div className="mainTitle">
                    I also have experience with
                </div>
                <div className="subTitle">
                    AWS Serverless Application Model
                </div>
                <div className="subTitle">
                    AWS Amplify
                </div>
                <div className="subTitle">
                    Full stack development and continuous integration/continuous development workflows
                </div>
            </div>
            <div className="container">
                <div className="mainTitle">
                    I was a part of
                </div>
                <div className="subTitle">
                    FIRST Tech Challenge Team #5466, The Combustible Lemons (2011-2015)
                </div>
                <div className="subTitle">
                    Lemelson-MIT Inventeams (2015 Davenport West High School)
                </div>
            </div>
            <div className="container">
                <div className="mainTitle">
                    My projects include
                </div>
                <div className="subTitle">
                    Remo.TV telepresence robot controller for Android (<a href="https://github.com/remotv/controller-for-android">GitHub</a>)(2018-present)
                </div>
                <div className="subTitle">
                    Controller app for the Sphero RVR on Android (<a href="https://play.google.com/store/apps/details?id=org.btelman.controller.rvr">Google Play</a>) (<a href="https://github.com/btelman96/controller-for-rvr">GitHub</a>)
                </div>
                <div className="subTitle">
                    Various telepresence robots using Android or Raspberry Pi (<a href="/robots">Robots</a>)
                </div>
                <div className="subTitle">
                    2 scoring apps for FIRST Tech Challenge (2015 and 2016)
                </div>
                <div className="subTitle">
                    Robot controller for NXT Lego Mindstorms for use on older FIRST Tech Challenge robots (before 2015-2016 game season)
                </div>
                <div className="subTitle">
                    NWS Weather Emergency Alert custom translator app. Developed with a FIRST Lego League team (2013-2014)
                </div>
                <div className="subTitle">
                    Android app developed when on the Lemelson-MIT Inventeam for a wearable device (2015)
                </div>
                <div className="subTitle">
                    Printed circuit boards for some robots occasionally
                </div>
                <div className="subTitle">
                    This website
                </div>
                <div className="subTitle">
                    FIRST Tech Challenge Team #5466 wordpress website (2012-2016)
                </div>
                <div className="subTitle">
                    Lemelson-MIT Inventeams wordpress website (2015)
                </div>
            </div>
            <div className="container">
                <div className="mainTitle">
                    Where can you find me?
                </div>
                <div className="subTitle">
                    <a href="https://www.linkedin.com/in/btelman/">LinkedIn</a>
                </div>
                <div className="subTitle">
                    <a href="https://www.thingiverse.com/skyeofbreeze/designs">Thingiverse</a>
                </div>
                <div className="subTitle">
                    <a href="/github">GitHub</a>
                </div>
                <div className="subTitle">
                    <a href="https://remo.tv/SkyeOfBreeze">Remo.TV</a>
                </div>
            </div>
        </div>
    }
}

export default Home